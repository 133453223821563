import { ReactElement } from "react";
import style from "./style.module.scss";

export default function Parkinson(): ReactElement {
    return (
        <div className={style.wrapper}>
            <div className="mx-auto px-5 lg:max-w-7xl flex">
                <div>
                    <h2>
                        How Does Paraquat Increase Risk of Parkinson's Disease?
                    </h2>
                    <p>
                        Paraquat causes damage to the neurons that produce the
                        neurotransmitter dopamine in the brain. Loss of dopamine
                        causes symptoms of Parkinson's disease, including the
                        impairment in fine motor skills.
                    </p>
                    <br />
                    <p>
                        Any low-level exposure to Paraquat can increase the risk
                        of developing Parkinson's disease - whether that is
                        through swallowing Paraquat, or simply living in
                        proximity to where the herbicide is being sprayed. In
                        2018, the University of Guelph conducted a study based
                        on human cells. It revealed that exposure to Paraquat,
                        as well as the fungicide Maneb, increased risk of
                        Parkinson's disease by 250 percent.
                    </p>
                </div>
            </div>
        </div>
    );
}
